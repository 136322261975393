import React from 'react';
import { Link } from 'gatsby';
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaFlickr,
  FaLinkedin,
  FaYoutubeSquare,
} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Identifier from './identifier';

const Icon = styled.a`
  &:hover {
    svg {
      fill: rgb(138, 39, 64);
    }
  }
`;

const Footer = ({ siteTitle, withIdentifier }) => (
  <>
    <footer className='usa-footer usa-footer--big' role='contentinfo'>
      <div className='grid-container usa-footer__return-to-top'>
        <Link to='#'>Return to top</Link>
      </div>
      {!withIdentifier && (
        <div className='usa-footer__primary-section'>
          <div className='grid-container'>
            <div className='grid-row grid-gap'>
              <div className='tablet:grid-col-12'>
                <nav className='usa-footer__nav'>
                  <div className='grid-row grid-gap-4'>
                    <div className='mobile-lg:grid-col-6 desktop:grid-col-3'>
                      <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                        <h4 className='usa-footer__primary-link'>Links</h4>
                        <ul className='usa-list usa-list--unstyled'>
                          <li className='usa-footer__secondary-link'>
                            <a
                              className='usa-link usa-link--external'
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.foia.gov/'
                            >
                              Freedom of Information Act
                            </a>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <a
                              className='usa-link usa-link--external'
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.goarmy.com/'
                            >
                              Go Army
                            </a>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <a
                              className='usa-link usa-link--external'
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.nlrb.gov/no-fear-act'
                            >
                              No Fear Act Data
                            </a>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/ig'>Office of the Inspector General</Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/privacy'>Privacy Policy</Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <a
                              className='usa-link usa-link--external'
                              target='_blank'
                              rel='noopener noreferrer'
                              href='mailto:medcoe_requestinfo@army.mil'
                            >
                              Request For Information
                            </a>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <a
                              className='usa-link usa-link--external'
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.usa.gov'
                            >
                              USA.gov
                            </a>
                          </li>
                        </ul>
                      </section>
                    </div>
                    <div className='mobile-lg:grid-col-6 desktop:grid-col-3'>
                      <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                        <h4 className='usa-footer__primary-link'>Center</h4>
                        <ul className='usa-list usa-list--unstyled'>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/appd'>AMEDD Personnel Proponent Directorate</Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/usamteac'>
                              U.S. Army Medical Test and Evaluation Activity
                            </Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/cdid'>
                              Capabilities Development & Integration Directorate
                            </Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/dotd'>Directorate of Training and Doctrine</Link>
                          </li>
                        </ul>
                      </section>
                    </div>
                    <div className='mobile-lg:grid-col-6 desktop:grid-col-3'>
                      <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                        <h4 className='usa-footer__primary-link'>School</h4>
                        <ul className='usa-list usa-list--unstyled'>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/32d-medbde'>32nd Medical Brigade</Link>
                          </li>
                           <li className='usa-footer__secondary-link'>
                            <Link to='/office-of-the-commandant'>
                              Office of the Commandant
                            </Link>
                          </li>
                           <li className='usa-footer__secondary-link'>
                            <Link to='/medcp'>
                              MEDCP
                            </Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/ncoa-new'>Noncommissioned Officers Academy</Link>
                          </li>
                        </ul>
                      </section>
                    </div>
                    <div className='mobile-lg:grid-col-6 desktop:grid-col-3'>
                      <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                        <h4 className='usa-footer__primary-link'>Other Organizations</h4>
                        <ul className='usa-list usa-list--unstyled'>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/dos'>Directorate of Simulation (DoS)</Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='/statistical-analysis-cell'>
                              Statistical Analysis Cell
                            </Link>
                          </li>
                        </ul>
                      </section>
                      <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                        <h4 className='usa-footer__primary-link'>Local Resources</h4>
                        <ul className='usa-list usa-list--unstyled'>
                          <li className='usa-footer__secondary-link'>
                            <Link to='https://www.jbsa.mil'>Joint Base San Antonio</Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='https://crg.amedd.army.mil/Pages/default.aspx'>
                              Army Community Resource Guide
                            </Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='https://www.jbsa.mil/News/News/Article/2341874/new-army-digital-garrison-app-serves-as-guide-to-on-post-services/'>
                              Digital Garrison App
                            </Link>
                          </li>
                          <li className='usa-footer__secondary-link'>
                            <Link to='https://www.jbsa.mil/News/News/Article/1239465/wecare-app-provides-soldiers-civilians-family-members-tools-for-us-army-sharpsa/'>
                              WeCareApp
                            </Link>
                          </li>
                        </ul>
                      </section>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      {withIdentifier && <Identifier siteTitle={siteTitle} />}
      {!withIdentifier && (
        <div className='usa-footer__secondary-section padding-y-9'>
          <div className='grid-container'>
            <div className='grid-row grid-gap'>
              <div className='usa-footer__logo grid-row mobile-lg:grid-col-6 mobile-lg:grid-gap-2'>
                <div className='mobile-lg:grid-col-auto'>
                  <StaticImage
                    src='../images/armylogotransparent.png'
                    css={{ width: '4rem' }}
                    alt='army logo'
                  />
                  <StaticImage
                    src='../images/medcoe-logo.svg'
                    style={{ width: '5.5rem', marginLeft: '1rem' }}
                    alt='medcoe logo'
                  />
                  <h3 className='usa-footer__logo-heading'>{siteTitle}</h3>
                </div>
              </div>
              <div className='usa-footer mobile-lg:grid-col-2'>
                <a
                  href='https://www.facebook.com/MedicalCoE/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <StaticImage
                    src='../images/MEDCoE_FB_QR_Code.png'
                    css={{ width: '8rem' }}
                    alt='link to medcoe facebook'
                  />
                </a>
              </div>
              <div className='usa-footer__contact-links mobile-lg:grid-col-4'>
                <div className='usa-footer__social-links grid-row grid-gap-1'>
                  <IconContext.Provider
                    value={{
                      color: '#212121',
                      size: '34px',
                    }}
                  >
                    <div className='grid-col-auto'>
                      <Icon
                        href='https://www.facebook.com/MedicalCoE/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaFacebookSquare />
                      </Icon>
                    </div>
                    <div className='grid-col-auto'>
                      <Icon
                        href='https://twitter.com/medcoe'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaTwitterSquare />
                      </Icon>
                    </div>
                    <div className='grid-col-auto'>
                      <Icon
                        href='https://www.instagram.com/medcoe/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaInstagram />
                      </Icon>
                    </div>
                    <div className='grid-col-auto'>
                      <Icon
                        href='https://www.flickr.com/photos/medcoe/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaFlickr />
                      </Icon>
                    </div>
                    <div className='grid-col-auto'>
                      <Icon
                        href='https://www.linkedin.com/company/medcoe/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaLinkedin />
                      </Icon>
                    </div>
                    <div className='grid-col-auto'>
                      <Icon
                        href=' https://www.youtube.com/medcoe/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaYoutubeSquare />
                      </Icon>
                    </div>
                  </IconContext.Provider>
                </div>
                <h3 className='usa-footer__contact-heading'>Connect With Us</h3>
                <address className='usa-footer__address'>
                  <div className='usa-footer__contact-info grid-row grid-gap'>
                    <div className='grid-col-auto'>
                      <a href='/contact-us'>
                        Contact Us
                      </a>
                    </div>
                  </div>
                </address>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  </>
);

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};
export default Footer;
