import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Banner = () => (
  <section className='usa-banner' aria-label='Official government website'>
    <div className='usa-accordion'>
      <header className='usa-banner__header'>
        <div className='usa-banner__inner'>
          <div className='grid-col-auto'>
            <StaticImage
              className='usa-banner__header-flag'
              src='../images/us_flag_small.png'
              alt='U.S. flag'
            />
          </div>
          <div className='grid-col-fill tablet:grid-col-auto'>
            <p className='usa-banner__header-text'>
              An official website of the United States government
            </p>
            <p className='usa-banner__header-action' aria-hidden={true}>
              Here’s how you know
            </p>
          </div>
          <button
            className='usa-accordion__button usa-banner__button'
            aria-expanded={false}
            aria-controls='gov-banner-dot-mil'
          >
            <span className='usa-banner__button-text'>Here’s how you know</span>
          </button>
        </div>
      </header>
      <div
        className='usa-banner__content usa-accordion__content'
        id='gov-banner-dot-mil'
        hidden
      >
        <div className='grid-row grid-gap-lg'>
          <div className='usa-banner__guidance tablet:grid-col-6'>
            <StaticImage
              className='usa-banner__icon usa-media-block__img'
              src='../images/icon-dot-gov.svg'
              role='img'
              alt='banner icon'
              aria-hidden='true'
            />
            <div className='usa-media-block__body'>
              <p>
                <strong>Official websites use .mil</strong>
                <br />A <strong>.mil</strong> website belongs to an official U.S.
                Department of Defense organization.
              </p>
            </div>
          </div>
          <div className='usa-banner__guidance tablet:grid-col-6'>
            <StaticImage
              className='usa-banner__icon usa-media-block__img'
              src='../images/icon-https.svg'
              role='img'
              alt='banner icon'
              aria-hidden='true'
            />
            <div className='usa-media-block__body'>
              <p>
                <strong>Secure .mil websites use HTTPS</strong>
                <br />A <strong>lock</strong> (
                <span className='icon-lock'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='52'
                    height='64'
                    viewBox='0 0 52 64'
                    className='usa-banner__lock-image'
                    role='img'
                    aria-labelledby='banner-lock-title-dot-mil banner-lock-description-dot-mil'
                    focusable='false'
                  >
                    <title id='banner-lock-title-dot-mil'>Lock</title>
                    <desc id='banner-lock-description-dot-mil'>A locked padlock</desc>
                    <path
                      fill='#000000'
                      fillRule='evenodd'
                      d='M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z'
                    />
                  </svg>
                </span>
                ) or <strong>https://</strong> means you’ve safely connected to the .mil
                website. Share sensitive information only on official, secure websites.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Banner;
