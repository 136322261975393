import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const Identifier = ({ siteTitle }) => (
  <div className='usa-identifier'>
    <section
      className='usa-identifier__section usa-identifier__section--masthead'
      aria-label='Agency identifier'
    >
      <div className='usa-identifier__container'>
        <div className='usa-identifier__logos'>
          <Link to='/' className='usa-identifier__logo'>
            <StaticImage
              className='usa-identifier__logo-img'
              src='../images/medcoe-logo.svg'
              alt='MEDCoE Parent Agency'
              role='img'
              layout='fixed'
              width={60}
            />
          </Link>
        </div>
        <div className='usa-identifier__identity' aria-label='Agency description'>
          <p className='usa-identifier__identity-domain'>{siteTitle}</p>
          <p className='usa-identifier__identity-disclaimer'>
            An official website of the{' '}
            <Link to='/'>U.S. Army Medical Center of Excellence</Link>
          </p>
        </div>
      </div>
    </section>
    <nav
      className='usa-identifier__section usa-identifier__section--required-links'
      aria-label='Important links'
    >
      <div className='usa-identifier__container'>
        <ul className='usa-identifier__required-links-list'>
          <li className='usa-identifier__required-links-item'>
            <Link to='/about-us'>About MedCoE</Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='http://www.section508.gov/'
              className='usa-identifier__required-link'
            >
              Accessibility support
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://www.tradoc.army.mil/FOIA/'
              className='usa-identifier__required-link usa-link'
            >
              FOIA requests
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://www.disa.mil/About/Legal-and-Regulatory/No-Fear-Act'
              className='usa-identifier__required-link usa-link'
            >
              No FEAR Act data
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link to='/ig' className='usa-identifier__required-link usa-link'>
              Office of the Inspector General
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            {/* TODO: Add this if we can */}
            <a href='#' className='usa-identifier__required-link usa-link'>
              Performance reports
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link to='/privacy' className='usa-identifier__required-link usa-link'>
              Privacy policy
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='mailto:medcoe_requestinfo@army.mil'
              className='usa-identifier__required-link usa-link'
            >
              Request for information
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <section
      className='usa-identifier__section usa-identifier__section--usagov'
      aria-label='U.S. government information and services'
    >
      <div className='usa-identifier__container'>
        <div className='usa-identifier__usagov-description'>
          Looking for U.S. government information and services?
        </div>
        <a href='https://www.usa.gov/' className='usa-link padding-left-05'>
          Visit USA.gov
        </a>
      </div>
    </section>
  </div>
);

Identifier.PropTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default Identifier;
